import React, { useEffect, useState } from "react";

import RotateScreen from "../views/RotateScreen";
import "../styles/globals__mobile.css";
import Cookies from "js-cookie";
import config from "../config";
import NavbarComponent from "./Navbar/NavbarComponent";
import { isMobileDevice } from "../scripts/helpers";
let setDimensions = true;
export default function MobileContainer(props) {
	const [orientation, setOrientation] = useState("");
	const [windowWidth, setWindowWidth] = useState(0);
	const [windowHeight, setWindowHeight] = useState(0);

	function setHeightWidth() {
		if (
			orientation == "landscape" &&
			props.correctOrientation == "landscape" &&
			props.page != "login" &&
			setDimensions
		) {
			setTimeout(() => {
				let height = Math.min(window.innerHeight, window.innerWidth);
				let width = Math.max(window.innerHeight, window.innerWidth);
				setWindowHeight(height);
				setWindowWidth(width);
				document.querySelector(".mobile-landscape-container").style.height =
					height + "px";
				document.querySelector(".mobile-landscape-container").style.width =
					width + "px";
			}, 200);
		}
	}
	useEffect(() => {
		const updateOrientation = () => {
			if (window.matchMedia("(orientation: portrait)").matches) {
				setOrientation("portrait");
			} else {
				setOrientation("landscape");
			}
		};

		updateOrientation(); // Set initial orientation

		document.querySelector("body").style.padding = 0;
		document.querySelector("body").style.backgroundImage = "none";
		document.querySelector("body").style.overflow = "visible";

		// Attach the event listener to detect orientation changes
		window.addEventListener("resize", updateOrientation);
		window.addEventListener("orientationchange", updateOrientation);

		return () => {
			window.removeEventListener("resize", updateOrientation);
			window.removeEventListener("orientationchange", updateOrientation);
		};
	}, []);
	useEffect(() => {
		setHeightWidth();
		console.log('ORIENTATION: ', orientation);
	}, [orientation]);

	return (
		<>
			{isMobileDevice() &&
			props.page == "login" &&
			orientation === props.correctOrientation ? (
				props.children
			) : isMobileDevice() &&
			  props.page == "login" &&
			  orientation !== props.correctOrientation ? (
				<RotateScreen
					display={
						isMobileDevice() && orientation === props.correctOrientation
							? "none"
							: "flex"
					}
				/>
			) : (
				<>
					<div
						className="mobile-landscape-container"
						style={{
							display:
								isMobileDevice() && orientation === props.correctOrientation
									? "block"
									: "none",
						}}
					>
						<NavbarComponent noNavbar={props.noNavbar} />
						{windowWidth &&
							windowHeight &&
							React.cloneElement(props.children, {
								...props.children.props,
								windowHeight: windowHeight,
								windowWidth: windowWidth,
							})}
					</div>
					<RotateScreen
						display={
							isMobileDevice() && orientation === props.correctOrientation
								? "none"
								: "flex"
						}
					/>
				</>
			)}
		</>
	);
}
