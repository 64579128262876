import React from "react";
import Lottie from "lottie-react";
import rotateScreen from "../assets/rotateScreen.json";

export default function RotateScreen(props) {
	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				justifyContent: "center",
				alignItems: "center",
				display: props.display,
			}}
		>
			<img
				src={require("../assets/login_page/main_background.png").default}
				style={{
					width: "100%",
					height: "100%",
					position: "fixed",
					bottom: 0,
					left: 0,
					zindex: -2,
				}}
			/>
			<Lottie animationData={rotateScreen} autoplay={true} loop={true} />;
		</div>
	);
}
